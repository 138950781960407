@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~@fancyapps/fancybox/dist/jquery.fancybox.css";
@import "./scale.scss";

html {
  font-size: 1rem;
}

body{
  background-image:url("../imgs/bg.jpg");
  /* ������� ����������� ������ �������������� �� ��������� � ����������� */
  background-position: center center;
  /* ������� ����������� �� ����������� */
  background-repeat: no-repeat;
  /* ������� ����������� ������������� � ���� ���������, ������� ��� �� ���������, ����� ������ �������� ������ ������ ����������� */
  background-attachment: fixed;
  /* ��� ��� ��������� �������� ����������� �������������� ��� ������ ���������� */
  -moz-background-size: 100%; /* Firefox 3.6+ */
  -webkit-background-size: 100%; /* Safari 3.1+ � Chrome 4.0+ */
  -o-background-size: 100%; /* Opera 9.6+ */
  background-size: cover;
/*  padding-top: 10rem;*/
  font-family: 'Verdana', sans-serif;
}
.clTransparent{
 background: rgba(235, 243, 250, 0.8);
}
.bg-navbar{
 background: rgba(65, 88, 156, 0.8);
}
.navbar .dropdown-menu {
     background: rgba(65, 88, 156,0.9);
  }
  .dropdown-menu a{ color:#fff; }
  .dropdown-menu a:hover{ background-color:transparent; color:#ccc; }
 
.clTitle{font-family: 'Verdana', cursive; color:#043561;}
.metadata{font-family:'Georgia',cursive; color:#043561;}
.clBrandStyled{font-family: 'Marck Script', sans-serif;}
.fntOswald{font-family: 'Oswald', sans-serif;}
.h-320px{height:320px !important;}
.h-200px{height:200px;}
.text-shadowed{
    text-shadow: 1px 1px 2px #000, 0 0 1em #666; /* ��������� ���� */
}
.picker-switch {background-color: #ccc;}
.card-link-overlay:hover{
    filter: gray; /* IE6-9 */
    -webkit-filter: grayscale(100%); /* Chrome 19+ & Safari 6+ */
}
.card-link-overlay:hover img{
     box-shadow: 5px 5px #292929;
}
.carousel-control-prev, .carousel-control-next {
  top: 50%;
  background: rgba(0, 0, 0, .6);
  height: 30px;
  width: 30px;
  /*border-radius: 15px;*/
}
@media (max-width: 1024px) {
 nav.navbar,.navbar .dropdown-menu {
     background: rgba(0,0,0,0.4);
  }
  .dropdown-menu a{ color:#fff; }
  .dropdown-menu a:hover{ background-color:transparent; color:#f33; }
}
.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}


